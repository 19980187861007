@import url(https://fonts.googleapis.com/css?family=Montserrat:400);
@import url(https://fonts.googleapis.com/css?family=Inconsolata:400,700);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;900&display=swap);
/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,400,700"); */
@font-face {
  font-family: "SofiaPro";
  src: local("SofiaPro"), url(/static/media/sofiapro-light.6bf30b6e.otf) format("opentype");
}

@font-face {
  font-family: "open sans";
  src: url(/static/media/OpenSans-Regular.d7d5d458.ttf) format("truetype");
}

@font-face {
  font-family: "open sans";
  src: url(/static/media/OpenSans-Bold.f5331cb6.ttf) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Gotham";
  src: url(/static/media/Gotham-Book.6c55eada.otf) format("opentype");
  font-style: normal;
  font-weight: 400;
}

@media print {
  .page-break {
    page-break-before: always;
  }

  .poi-icon {
    background-color: "#009de0";
    -webkit-print-color-adjust: exact;
  }

  /* body * {
    visibility: hidden;
  }

  #reportContent,
  #reportContent * {
    visibility: visible;
  }

  #reportContent {
    position: absolute;
    left: 0;
    top: 0;
  } */
}

/* Removes title and date from page on print */
@page {
  size: auto;
  margin: 0mm;
}

@media print {
  .no-print {
    display: none;
  }

  .break {
    page-break-after: always;
    -webkit-column-break-after: always;
            break-after: always;
  }

  .break:last-child {
    page-break-after: auto;
    -webkit-column-break-after: auto;
            break-after: auto;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body > svg {
  display: none;
}

#reportContent {
  width: 700px;
  height: 1000px;
}
.mapboxgl-ctrl {
  /* position: absolute; */
  margin: 0;
  top: 10px;
  left: 20px;
  overflow: hidden;
  /*
  width: 40px;
  border: none !important;
   border-radius: 20px;
  -webkit-box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.4) !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.4) !important; */
}
a.mapboxgl-ctrl-logo {
  display: none !important;
}
/* 
.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in {
  background-size: 20px;
  width: 40px;
  height: 40px;
  background-image: url("./assets/images/add.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}
.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out {
  background-size: 20px;
  width: 40px;
  height: 40px;
  background-image: url("./assets/images/minus.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}
*/
.firebaseui-idp-button {
  max-width: 100% !important;
}

.firebaseui-card-footer {
  padding: 0 !important;
}

.firebaseui-card-content {
  padding: 0 !important;
}

input:focus-visible {
  outline: none;
}

